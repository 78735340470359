import React, { ReactNode, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';
import { SwatchesPicker, ColorChangeHandler } from 'react-color';
import { Div, Button } from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';

import { vkActions } from 'src/store/vk/reducer';
import { ModalName } from 'src/store/vk/types';

type ColorSelectOwnProps = {
  TextPreview?: ReactNode;
};

type ColorSelectProps = WrappedFieldProps & ColorSelectOwnProps;

export const ColorSelect = ({ input, TextPreview }: ColorSelectProps) => {
  const dispatch = useDispatch();

  const handleCompleteChange: ColorChangeHandler = useCallback(
    ({ hex }) => {
      input.onChange(hex);
    },
    [input]
  );

  const handleOpen = useCallback(() => {
    dispatch(vkActions.setActiveModal({ modal: ModalName.MAIN }));
    dispatch(
      vkActions.setModalContent({
        content: () => (
          <SwatchesPicker
            color={input.value}
            onChangeComplete={handleCompleteChange}
            onChange={handleCompleteChange}
            width={500}
            height={430}
          />
        ),
        title: 'Выберите цвет',
      })
    );
  }, [dispatch, handleCompleteChange, input.value]);

  return (
    <ColorPickerField>
      <Div className="wrap">
        <ColorPreview color={input.value} onClick={handleOpen} />
        <Button size="m" onClick={handleOpen}>
          Изменить
        </Button>
      </Div>

      {TextPreview}
    </ColorPickerField>
  );
};

const ColorPickerField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .wrap {
    display: flex;
    align-items: center;
  }
`;

const ColorPreview = styled.div<{ color: string }>`
  flex: none;
  margin-right: 8px;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(38, 50, 56, 0.3);
  border-radius: 4px;
  background-color: ${(p) => p.color};
  cursor: pointer;
`;
