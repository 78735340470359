import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Input, Textarea } from '@vkontakte/vkui';
import { WrappedFieldProps } from 'redux-form';

type TextFieldOwnProps = {
  className?: string;
  top?: string;
  bottom?: string;
};

type TextFieldProps = WrappedFieldProps & TextFieldOwnProps;

export const TextField: FC<TextFieldProps> = ({ input, meta, ...rest }) => {
  return <Input {...input} {...rest} />;
};

type TextAreaOwnProps = {
  className?: string;
  top?: string;
  bottom?: string;
  maxLength?: number;
};

type TextAreaProps = WrappedFieldProps & TextAreaOwnProps;

export const TextArea: FC<TextAreaProps> = ({ input, className, maxLength }) => {
  const [value, setValue] = useState(input.value);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      input.onChange(event.target.value);
    },
    [input]
  );

  return (
    <Textarea
      value={value}
      onChange={onChange}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      className={className}
      maxLength={maxLength}
    />
  );
};
