import { FormLayout, FormLayoutGroup, ScreenSpinner, Separator } from '@vkontakte/vkui';
import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { RootState } from 'src/core/rootReducer';
import { BaseGame } from 'src/games/common/types';
import { myGamesActions } from 'src/store/my-games/actions';
import { vkActions, VKInitialState } from 'src/store/vk/reducer';
import { TextField, TextArea } from 'src/vk-app/components';
import { usePrevious } from 'src/core/hooks/usePrevious';

const prizeName = '{prize_name}';
const prizePromocode = '{prize_promocode}';
const prizeDescription = '{prize_description}';

type Props = {
  additional?: ReactNode;
};

const Texts: FC<Props> = ({ additional = null }) => {
  const values = useSelector<RootState>((state) => state.form.game.values) as BaseGame;
  const dispatch = useDispatch();
  const vkData = useSelector<RootState, VKInitialState>((state) => state.vk);

  const handleSubmit = useCallback(() => {
    dispatch(vkActions.setPopoutContent({ popout: <ScreenSpinner /> }));
    dispatch(myGamesActions.saveSettings.started({ gameId: values.gameId }));
  }, [dispatch, values.gameId]);

  const isCompetition = values.mainGameSettings.campaignType === 'competition';

  const prevRequestSave = usePrevious(vkData.requestSave);

  useEffect(() => {
    if (vkData.requestSave && !prevRequestSave) {
      handleSubmit();
    }
  }, [vkData.requestSave, handleSubmit, prevRequestSave]);

  return (
    <Wrap>
      <FormLayout>
        <FormLayoutGroup top={`Заголовок (${values.titleText.length}/40)`}>
          <Field name="titleText" component={TextField} maxLength={40} />
        </FormLayoutGroup>

        <FormLayoutGroup top={`Подзаголовок (${values.subtitleText.length}/240)`}>
          <Field name="subtitleText" component={TextArea} maxLength={240} />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup
          top={`Заголовок экрана выигрыша (${values.winTitle.length}/60)`}
          bottom={
            isCompetition
              ? '{prize} заменится на кол-во набранных очков'
              : '{prize} заменится на название выигранного приза'
          }
        >
          <Field name="winTitle" component={TextField} maxLength={60} />
        </FormLayoutGroup>

        <FormLayoutGroup top={`Заголовок экрана проигрыша (${values.failTitle.length}/60)`}>
          <Field name="failTitle" component={TextField} maxLength={60} />
        </FormLayoutGroup>

        <Separator />

        <FormLayoutGroup
          top={`Текст сообщения. Отправится игроку в ЛС. Необходимо включить сообщения сообщества. (${values.vkTextMessage.length}/400)`}
          bottom={
            isCompetition ? (
              <>{prizeName} заменится на кол-во набранных очков</>
            ) : (
              <>
                {prizeName} заменится на название выигранного приза
                <br />
                {prizePromocode} на значение (секретную часть)
                <br />
                {prizeDescription} на описание
              </>
            )
          }
        >
          <Field name="vkTextMessage" component={TextArea} maxLength={400} />
        </FormLayoutGroup>

        {additional}
      </FormLayout>
    </Wrap>
  );
};

const Wrap = styled.div``;

const withForm = reduxForm<any, any, any>({
  form: 'game',
})(Texts);

const withStore = connect((state: RootState) => {
  const { mainSettingsId } = state.vk.gameSettings;

  return {
    initialValues: state.myGames.data.find((game) => game.mainGameSettings.id === mainSettingsId),
  };
}, {})(withForm);

export { withStore as Texts };
